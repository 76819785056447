import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HowItWorks from "../components/Home/HowItWorks";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import "./ProductPage.css";
import website from "../website/data";

const ProductPage = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("ALL");
  const [products, setProducts] = useState(null);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(false);

  async function getAllProducts() {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setData(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Layout>
      <div className="product-page-container">
        <div className={`category-filter d-block d-lg-none`}>
          <h5 className="m-0" onClick={() => setFilter(!filter)}>
            Filter
          </h5>
        </div>
        <div className={`category-slider ${filter && "active"}`}>
          <ul>
            <li
              className={`${category === "ALL" && "active"}`}
              onClick={() => {
                setCategory("ALL");
                setFilter(!filter);
              }}
            >
              All
            </li>
            <li
              className={`${category === "Popular Games" && "active"}`}
              onClick={() => {
                setCategory("Popular Games");
                setFilter(!filter);
              }}
            >
              Popular Games
            </li>
            <li
              className={`${category === "Mobile Games" && "active"}`}
              onClick={() => {
                setCategory("Mobile Games");
                setFilter(!filter);
              }}
            >
              Mobile Games
            </li>
            <li
              className={`${category === "PC Games" && "active"}`}
              onClick={() => {
                setCategory("PC Games");
                setFilter(!filter);
              }}
            >
              PC Games
            </li>
            <li
              className={`${category === "Social Media Services" && "active"}`}
              onClick={() => {
                setCategory("Social Media Services");
                setFilter(!filter);
              }}
            >
              Social Media Services
            </li>
            <li
              className={`${category === "Other" && "active"}`}
              onClick={() => {
                setCategory("Other");
                setFilter(!filter);
              }}
            >
              Other
            </li>
          </ul>
        </div>
        <div className="products-container">
          {products
            ?.filter((item) => {
              if (category === "ALL") {
                return item;
              } else {
                return item?.category === category;
              }
            })
            .map((item, index) => {
              return (
                <div
                  className="product-box"
                  onClick={() => navigate(`/product/${item?.name}`)}
                >
                  <div className="product-img">
                    <img src={`${website.link}/${item?.image}`} alt="" />
                  </div>
                  <h5>{item?.name}</h5>
                </div>
              );
            })}
        </div>
      </div>
      {/* <HowItWorks /> */}
    </Layout>
  );
};

export default ProductPage;
