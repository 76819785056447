import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "../Footer/Footer.css";
import IMAGES from "../../img/image";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        {/* <Link
          className="whatsapp-icon-cont"
          to="https://wa.me/917005447895"
          target="_blank"
        >
          <WhatsAppIcon className="icon" />
        </Link> */}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo" onClick={() => navigate("/")}>
              {/* <img src={IMAGES.logo} alt="" /> */}
              <h4>Emartop</h4>
            </div>
            <p>
              Enjoy the experience of purchasing automatic game Top Up whenever
              and wherever you want. One of the most cheapest and fastest
              service
            </p>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <ul>
              {!user ? (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Links</h6>
            <ul>
              <li>
                <Link to="/terms-and-conditions">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Payments Channel</h6>
            <div className="payments-channel">
              <marquee behavior="smooth" direction="">
                <img src={IMAGES.upi} alt="" />
                <img src={IMAGES.paytm} alt="" />
                <img src={IMAGES.phonepe} alt="" />
                <img src={IMAGES.gpay} alt="" />
              </marquee>
            </div>
          </div>

          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2024 | EMARTOP |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "var(--t)", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
