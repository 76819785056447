import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import website from "../../website/data";
import "./HeroSection.css";

const HeroSection = () => {
  const arrowRef = useRef();
  const [banners, setBanners] = useState(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  async function getBanners() {
    try {
      const res = await axios.get("/api/banner/get-banners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setBanners(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      {/* Desktop and Tablet  */}
      <div className="container-fluid hero-container">
        <Slider ref={arrowRef} {...settings}>
          {banners &&
            banners?.map((item, index) => {
              return (
                <div key={index} className="p-1 p-lg-2">
                  <Link to={item?.link} target="_blank">
                    <img
                      src={`/${item.image}`}
                      className="d-block w-100"
                      alt="banners"
                    />
                  </Link>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
};

export default HeroSection;
