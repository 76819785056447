import React from "react";
import { Link } from "react-router-dom";
import website from "../../website/data";
import IMAGES from "../../img/image";
import "./FollowUs.css";

const FollowUs = () => {
  return (
    <div className="followus-container">
      <span>
        <small>Customer Service</small>
      </span>
      <h2>Reach Us At</h2>
      <div className="socials">
        <Link target="_blank" to={`${website.instagram}`}>
          <img src={IMAGES.facebook} alt="" />
        </Link>

        <Link target="_blank" to={`${website.instagram}`}>
          <img src={IMAGES.instagram} alt="" />
        </Link>

        <Link target="_blank" to={`https://wa.me/${website.mobile}`}>
          <img src={IMAGES.whatsapp} alt="" />
        </Link>

        {/* <Link target="_blank" to="https://t.me/clovershopindia">
          <img src={IMAGES.telegram} alt="" />
        </Link> */}

        <Link target="_blank" to={`mailto:${website.email}`}>
          <img src={IMAGES.gmail} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default FollowUs;
