import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const renderCategory = (category, maxItems) => {
    const filteredProducts = products?.filter(
      (item) => item.category === category
    );
    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <div className="popular-games">
          <div className="titlee">
            <h5 className="m-0">{category}</h5>
            <span onClick={() => navigate("/games")}>View More</span>
          </div>
          <div className="games-list">
            {filteredProducts.slice(0, maxItems).map((item, index) => (
              <div
                key={index}
                className="game"
                onClick={() => navigate(`/product/${item?.name}`)}
              >
                <img src={`/${item?.image}`} alt="pro-img" />
                <div className="company">
                  <p>{item?.company}</p>
                  <h5>{item?.name}</h5>
                </div>
                <button className="buy-now">TOP UP</button>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="explore-products-container">
      {renderCategory("Popular Games", 1000)}
      {renderCategory("Mobile Games", 1000)}
      {renderCategory("PC Games", 1000)}
      {renderCategory("Social Media Services", 1000)}
      {renderCategory("Other", 1000)}
    </div>
  );
};

export default Games;
