const website = {
  link: "https://emartop.in",
  name: "Emartop",
  email: "bleh1409@gmail.com",
  mobile: "919938763853",
  instagram: "https://www.instagram.com/emart.mlbb",
  facebook: "",
};

module.exports = website;
